<template>
    <div class="content">
        <!-- <Menu></Menu> -->
        <TopBanner :srcTop = "srcTop"></TopBanner>
        <Module ModuleTitle1="诚邀" ModuleTitle2="合作" ModuleTitleEng="Sincerely invite cooperation">
          <template #content>
            <div class="cooperWidth">
              <div class="cooprateStrength">
                <div class="strengthBg"><img :src="cooperateData.strengthImg " alt=""></div>
                <div class="strengthText">
                  <div class="strengthTitle">合作优势</div>
                  <div class="strengthTips">
                      重庆美心(集团)有限公司(以下简称:美心集团)，创建于1989年，现有员工逾万人，已成为门业研发制造为主导产业，投资涵盖旅游地产、汽摩配件、国际贸易、精密制造、养老等多个领域
                      的大型民营企业，产品远销世界各地，工程项目遍及中国、美国、
                      英国、法国、波兰、南亚和东南亚等四十多个国家和地区，出口销售额超过1.8亿美元，公司年销售总额超过40亿元。
                  </div>
                </div>
                <div class="telStyle">
                  <img src="../static/phoneIcon.png" alt="">
                  <div>023-6281-1555</div>
                </div>
              </div>
            </div>
          </template>
        </Module>
        <div class="termStyle cooperWidth">
          <img :src="cooperateData.termImg" alt="">
        </div>
        <!-- <div class="advStyle"><img :src="cooperateData.cooperBanImg" alt=""></div> -->
        <div class="cooperWidth">
          <div class="cooProcess">
            <div class="cooProcessTitle"> 合作流程</div>
            <div><i class="el-icon-caret-bottom"></i></div>
            <div><img :src="cooperateData.cooProcessImg" alt=""/></div>
          </div>
        </div>
        <Footer>
          <template #footerBanner>
                <img :src=" $SrcUrl  + srcFooter" alt="">
          </template>
        </Footer>
    </div>
  </template>
  
  <script>
  import Menu from "@/components/Menu"
  import TopBanner from "@/components/TopBanner"
  import Module from "@/components/Module"
  import Footer from "@/components/Footer"
  import { pictureData } from "@/utils/advertiseImgData"

  export default {
    name: "CompanyProfile",
    components: {
      Menu,Module,Footer,TopBanner
    },
    data () {
      return {
        windowHeight:'',
        cooperateData:{
            strengthImg:require('@/static/CompanyProfile_banner.png'),
            termImg:require('@/static/cooperativeTerms.png'),
            cooperBanImg:require('@/static/cooperateBanner1.png'),
            cooProcessImg:require('@/static/cooperationProcess.png'),
        },
        topBanner:[require('@/static/cooperateBanner.png')],
        srcTop:[],
        srcFooter:[require('@/static/fooderImg1.png')],
    }
    },
    methods: {
      getAdvertise(){
        this.$http.get('/system/advertisement/getAdvS').then(res => {
          let { topImg, footerImg } = pictureData({picExplain:'诚邀合作', data:res})
          console.log('topImg',topImg);
          console.log('footerImg',footerImg);
          if(topImg.length > 0){
            this.srcTop = topImg[0].data;
          }
          // if(footerImg.length > 0){
          //   this.srcFooter = footerImg[0].data
          // }
        })
      },
    },
    mounted(){
      this.getAdvertise();
      this.windowHeight = window.innerHeight;
    },
    created() {},
  }
  </script>
  
  <style lang="scss" scoped>
  .content{
    width: 100%;
  }
  .cooperWidth{
    width: 62%;
    margin: 5% 19%;
    display: flex;
    justify-content: center;
  }
  .cooprateStrength{
    width: 100%;
    justify-content: center;
    position: relative;
  }
  .cooprateStrength .strengthBg,.cooprateStrength .strengthBg img{
    width: 100%;
  }
  .cooprateStrength .strengthText{
    position: absolute;
    top: 62px;
    right: 41px;
    width: 42%; 
    max-width: calc(40em * 0.5);
    background: rgba(0,0,0,0.4);
    color: #FFFFFF;
    font-family: Source Han Sans CN;
    font-size: 16px;
    text-align: justify;
    padding: 70px 88px 45px 107px; 
  }
  .cooprateStrength .strengthTitle{
    position: absolute;
    font-size:calc(100vw * 50 / 1920);
    font-weight: bold;
    text-align: center;
    top: -21px;
    right: 28%;
    text-shadow:2px 3px 1px #000000;
  }
  .cooprateStrength .strengthText .strengthTips {
    font-size:calc(100vw * 18 / 1920);
    // text-overflow: -o-ellipsis-lastline;
    // overflow: hidden;				//溢出内容隐藏
    // text-overflow: ellipsis;		//文本溢出部分用省略号表示
    // display: -webkit-box;			//特别显示模式
    // -webkit-line-clamp: 9;			//行数
    // line-clamp: 2;					
    // -webkit-box-orient: vertical;	//盒子中内容竖直排列
  }
  .cooprateStrength .telStyle{
    position: absolute;
    left: 81px;
    bottom: 81px;
    display: flex;
    align-items: center;
    font-size:calc(100vw * 30 / 1920);
    color: #FFFFFF;
    img{
      width: 55px;
      height: 55px;
    }
  }
  .termStyle img{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .advStyle img{
    width: 100%;
    height: 100%;
  }
  .cooProcess{
    margin: 30px 0;
  }
  .cooProcess div{
    display:flex;
    justify-content: center;
    font-size: 47px;
    font-weight: 400;
  }
  .cooProcess div:nth-child(2){
    color: #0069AB;
  }
  .cooProcess div:last-child{
    padding-top: 40px;
  }
  .cooProcess div img{
    width: 100%;
  }
  </style>