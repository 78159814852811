<template>
    <div class="moduleContent">
      <div>
        <div class="titleCHN word1">{{ModuleTitle1}}<span style="color:#005189;">{{ModuleTitle2}}</span></div>
        <div class="titleEng word1">{{String(ModuleTitleEng).toUpperCase()}}</div>
        <el-divider></el-divider>
        <div>
          <div class="tabPage"><slot name="content"/></div>
        </div>
      </div>
    </div>
</template>
<script>
  export default{
    props:({
      ModuleTitle1: {
        type: String,
        default: ""
      },
      ModuleTitle2: {
        type: String,
        default: ""
      },
      ModuleTitleEng:{
        type: String,
        default: ""
      }
    }),
    name: "Module",
    data() {
      return {
        activeName: 'first',
        cur:0 //默认选中第一个tab
      }
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    }
  }
</script>

<style scoped>
  .moduleContent{
    width: 100%;
    padding-top: 30px;
  }
  .word1{
    color: #333333;
    font-family: Source Han Sans CN;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .titleCHN{
    font-size: 42px;
    font-weight: 500;
  }


  
  .tabModule{
    width: 100%;
  }
  .tabModule .tabHeader{
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: nowrap
  }
  .tabModule .tabHeader span{
    padding: 4px 10px;
    margin: 3px 36px;
  }
  .tabModule .tabHeader .active{
    color: #FFFFFF;
    background-color: #005189;
    border-radius: 4px;
  }
  .tabModule .tabContent{
    margin: 50px 0;
  }
  .tabModule .tabContent .tabModuleItem{
    width: 100%;
  }

</style>