<template>
  <div class="footerContain">
    <!-- <div class="footerBanner"> 
      <slot name='footerBanner'></slot>
    </div> -->
    <div class="footerContent">
      <div>
        <div class="content_top">
          <div class="tableUl">
            <ul v-for="item in footerMenuData" :key="item.id">
              <div v-if="item.name == '联系我们'">
                <li class="sec" @click="menuClick(item)">{{item.name}}</li>
                <li class="secChild">
                  <div style="display:flex;align-items: center;justify-content: center;">
                    <div>
                      <img src="../static/phoneIcon_Small.png" alt="">
                    </div>
                    <div>
                      <div>
                        400-023-5968
                      </div>
                      <div>
                        023-62811555
                      </div>
                    </div>
                  </div>
                  <div style="display:flex;align-items: center;justify-content: center;">
                    <img src="../static/positionIcon_samll.png" alt="">重庆市南岸区白鹤路56号
                  </div>
                </li>
              </div>
              <div v-else>
                <li class="sec" @click="menuClick(item)">{{item.name}}</li>
                <li class="sec secChild" v-for="itm in item.chilren" :key="itm.id">
                  <span v-if="itm.name!='住宅系统'" @click="menuClick(itm)">{{itm.name}}</span>
                </li>
              </div>
            </ul>
          </div>
          <div class="leaveMsgForm">
            <el-form ref="suggest" :model="suggest">
              <el-form-item>
                <el-input v-model="suggest.name"  placeholder="您的姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input  type="number" v-model.number="suggest.contact" placeholder="您的联系方式"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input type="textarea" v-model="suggest.content" placeholder="您的需求与建议"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="codeStyle">
                  <el-input v-model="suggest.code" placeholder="验证码"></el-input>
                  <img :src="captcha.img" alt="">
                  <span @click="changeCode()">换一张</span><br />
                </div>
              </el-form-item>
              <el-form-item>
                <el-button @click="onSubmit">提交留言</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="QR_code">
            <div><img src="../static/QRcode_Footer.jpg" alt=""></div>
            <div>扫一扫！关注微信！</div>
          </div>
        </div>
        <div style="clear:both;"></div>
        <!-- <div class="footerLine"></div> -->
        <div style="clear:both;"></div>
        <div class="footerContentDown secChild">
          <div class="footerText">Copyright © https://www.svndoor.com/ 萨瓦尼入户门,商用门-重庆五州门业&nbsp;&nbsp;<span @click="goICP">渝ICP备17001452号-6</span></div>
          <div class="footerText police" @click="goPolice"> <img src="../static/police.png" alt=""> 渝公网安备 50010802003912号</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    name: "Footer",
    data() {
      return {
        captcha:'',
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        suggest:{
          name: '',
          contact: '',
          content: '',
          code:''
        },
        footerMenuData:{},
      }
    },
    methods: { 
      menuClick(item){
        if(item.cataLoguePCS == '/ProductCenter'){
          sessionStorage.setItem('TypeId',item.id)
        }
        this.$router.push({ 
          path: item.cataLoguePCS
        });
        location.reload();
      },
      getFooterMenu(){
        this.$http.get('/system/situation/getTreeS').then(res => {
          console.log('res',res);
          this.footerMenuData = res
          // console.log('this.footerMenuDat',this.footerMenuDat);
        })
      },
      // 获取验证码
      getCaptcha(){
        this.$http.get('/captchaImageWuZhou').then(res => {
          if(res.code == 200){
            this.captcha = res
          }
        })
      },
      onSubmit(){
        if(this.suggest.name==""){
          alert( '请输入姓名');
          this.getCaptcha();
        }else if(this.suggest.contact==""){
          alert('请输入手机号')
          this.getCaptcha();
        }else if(this.suggest.content==""){
          alert('请输入您的建议')
          this.getCaptcha();
        }else if(this.suggest.code==""){
          alert('请输入验证码')
          this.getCaptcha();
        }else{
          this.suggest.uuid = this.captcha.uuid;
          console.log('this.suggest-----------',this.suggest);
          this.$http.post('/system/xunpan/code',this.suggest).then(res => {
            console.log('res-----------',res);
            if(res.code == 200){
              this.suggest = {}
              this.getCaptcha();
              alert('提交成功')
              location.reload();
            }else if(res.code == 500){
              alert(res.msg)
              this.getCaptcha();
            }
          })
        }
        

      },
      changeCode(){
        this.getCaptcha();
      },
      goICP(){
        var url = 'http://beian.miit.gov.cn/'
        window.open(url,"_blank");
      },
      goPolice(){
        var url = 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802003912'
        window.open(url,"_blank");
      },
    },
    mounted(){
      setTimeout(()=>{
        const elementResizeDetectorMaker = require("element-resize-detector");
        let erd = elementResizeDetectorMaker();
        erd.listenTo(document.querySelector(".footerContain"), (ele) => {
        // this.bannerFitWidth = ele.offsetWidth*0.495
        // document.querySelector('.footerBanner').style.height = ele.offsetWidth*0.25
      });},100)
      this.getFooterMenu();
      this.getCaptcha();
    }
  }
</script>

<style lang="scss" scoped>
.footerContain{
  width: 100%;
  background-color: #000000;
  color: #fff;
  width: 100%;
}
.footerBanner{
  width: 100%;
  margin-bottom: -4px;
  // height: 480px;
  overflow: hidden;
}
.footerBanner img{
  // width: 100%;
  // height: 480px;
  height: 100%;
}
.footerContent{
  overflow: hidden;
  color: white; 
  height: 300px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}
.footerLine{
  height: 4px;
  width: 90%;
  border-radius: 50%;
  margin: 0 5% 0;
  background-color: white;
}
.content_top{
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.tableUl{
  display: flex;
  white-space:nowrap;
}
.tableUl ul{
  list-style-type: none;
  margin-top: unset;
  padding-left: 20px;
}
.tableUl ul li{
  text-align: center;
  margin: 0 0 12px 0;
}
.tableUl ul li:first-child{
  font-size: 17px;
}
.tableUl .sec:hover{
  color: #009dff;
}
.tableUl ul:last-child li{
  text-align: left;
}
.tableUl .secChild{
  font-size: 13px;
}

/* 表单 */
.leaveMsgForm{
  margin-left: 48px;
  float: left;
}
.leaveMsgForm ::v-deep .el-form .el-form-item .el-input__inner,
.leaveMsgForm ::v-deep .el-form .el-form-item .el-textarea .el-textarea__inner{
  width: 220px;
  height:28px;
  color: white;
  background-color: #000000;
}
.leaveMsgForm ::v-deep .el-form .el-form-item .el-form-item__content{
  line-height: normal;
}
.leaveMsgForm  ::v-deep .codeStyle .el-input__inner{
  /* width: 74px !important; */
  width: 98px!important;
}
.leaveMsgForm ::v-deep .el-form .el-form-item .el-form-item__content .el-button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height:28px;
}
.leaveMsg form input::-webkit-input-placeholder,textarea::-webkit-input-placeholder {   
    color: rgb(226, 224, 224);
}
.leaveMsgForm .el-form .el-form-item {
    margin-bottom: 0 !important;
}

/* 验证码 */
.leaveMsgForm .codeStyle{
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.leaveMsgForm .codeStyle img{
  width: 80px;
  height: 30px;
}

/* 二维码 */
.QR_code{
  float: right;
  padding-left: 10px;
  white-space: nowrap;
}
.QR_code img{
  width: 126px;
  height: 126px;
}



/* .leaveMsg{
  display: flex;
  margin-left: 100px;
} */
/* .leaveMsg .leaveMsgForm,.QR_code img{
  width: 126px;
  height: 126px;
} */
/* .leaveMsg .leaveMsgForm,.QR_code{
  margin: 0 30px;
}
 */
 .footerContentDown{
  width: 100%;
  .footerText{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .police img{
      width: 20px;
      height: 20px;
    }
  }
 }
</style>