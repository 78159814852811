<template>
    <div>
        <Menu></Menu>
        <div id="wrap" :style="{ height: screenHeight + 'px' }">
            <div id="main" :style="{ top: nowTop + 'px' }">
                <!-- <ul id="pageUl" type="circle">
                    <li id="pageUlLi1" class="pageUlLi" :class="{'active': curIndex == 1}">&nbsp;</li>
                    <li id="pageUlLi2" class="pageUlLi" :class="{'active': curIndex == 2}">&nbsp;</li>
                    <li id="pageUlLi3" class="pageUlLi" :class="{'active': curIndex == 3}">&nbsp;</li>
                    <li id="pageUlLi4" class="pageUlLi" :class="{'active': curIndex == 4}">&nbsp;</li>
                    <li id="pageUlLi5" class="pageUlLi" :class="{'active': curIndex == 5}">&nbsp;</li>
                </ul> -->
                <div id="page1" class="page">
                  <TopBanner :srcTop = "srcTop"  :insideBanner="false"></TopBanner>
                </div>
                <div id="page2" class="page">
                  <Module id="companyIntro" ModuleTitle1="公司" ModuleTitle2="介绍" ModuleTitleEng="company introduction">
                    <template #content>
                      <el-tabs v-model="activeName_companyKey" @tab-click="handleClick" class="tabStyle">
                        <el-tab-pane v-for="item in companyKey" :key="item.id" :label="item.name" :name="String(item.id)"></el-tab-pane>
                      </el-tabs>
                    </template>
                  </Module>
                  <el-row>
                    <el-col>
                      <div v-show=" activeName_companyKey == '21'" class="tabModuleItem">
                        <AboutUs></AboutUs>
                      </div>
                      <div v-show=" activeName_companyKey == '22'" class="tabModuleItem">
                        <CompanyStrength></CompanyStrength>
                      </div>
                      <div v-show=" activeName_companyKey == '26'" class="tabModuleItem">
                        <Honors></Honors>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div style="background: #f7f8f8;" id="page3" class="page">
                    <Module ModuleTitle1="产品" ModuleTitle2="中心" ModuleTitleEng="Product center">
                        <template #content>
                            <el-tabs v-model="activeName" @tab-click="handleClick" class="tabStyle">
                                <el-tab-pane v-for="item in doorsType" :key="item.id" :label="item.name" :name="String(item.id)"></el-tab-pane>
                            </el-tabs>
                        </template>
                    </Module>
                    <div style="display:flex;justify-content:center;width: 100%;position: relative;">
                      <div class="hospitalDoor">    
                        <div class="hospitalDoor_content1">
                          <template v-if="doorsAdvData.length != 0">
                            <div style="font-size:36px;writing-mode: tb-rl;position: absolute;left: 15%;">{{doorsAdvData[0].titleC}}</div>
                            <div style="color: #0069AB;font-size: 32px;writing-mode: tb-rl;position: absolute;left: 20%;top: 8%;z-index: 9999;">{{doorsAdvData[0].titleE}}</div>
                            <div style="width:60%" @click="goProductDetails(doorsAdvData[0].id)">
                            <img :src=" $SrcUrl  +  doorsAdvData[0].picture" style="border: 14px solid #A7A7A7;fit:contain;width: 100%;margin-top: 18%;" alt="">
                            </div>
                            <div style="position: absolute;right: 1%;top:0;width: 45%;">
                            <div  @click="goProductDetails(doorsAdvData[1].id)">
                                <img :src="$SrcUrl  +  doorsAdvData[1].picture" style="border: 6px solid #377CA7;width: 100%;fit:contain;" alt="">
                            </div>
                            <div style="text-align: center;margin-top: 24%;font-size: 20px;"><div @click="goMoreProduct(activeName)">查看更多></div><div>-</div></div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                </div>
                <div id="page4" class="page">
                  <div class="hospitalDoorArea" style="display:flex;justify-content:center;width: 100%;position: relative;">
                    <div class="hospitalDoor2"  style="margin:auto 0;">
                      <div class="hospitalDoor_content2">
                        <img :src=" $SrcUrl + productAreaData.logo" style="width: 100%;" alt="">
                        <div class="hospitalSubarea">
                          <div class="hospitalSubarea_content" v-for="item in productAreaData.logoWords" :key="item.id">
                          <div class="hosAreaTitle center">{{item.logoTypeName}}</div>
                          <div class="center">{{String(item.englishName).toUpperCase()}}</div>
                          <div  :style="{'background': item.logoColor,width:'100%',height:'8px'}"></div>
                          <div class="center">
                            <div>
                              <div v-for="(item1,index) in item.child" :key="index">
                                  <div style="display:flex;" @click="goProductDetails(item1.productId)">
                                  <div style="width:20px;height:20px;background:#8e2a23;border-radius:50%;text-align:center;color:white;margin-right: 6px;">{{item1.sort}}</div>{{ item1.name }}
                                  </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bg">
                      <img src="../static/honorBgImg_01.png" alt="" style="width: 100%;">
                    </div>
                  </div>
                </div>
                <div style="background-color: #66512c" id="page5" class="page">
                  <div class="caseFoot">
                    <div class="exampleCase">
                      <div class="exampleCaseOne">
                        <div class="exampleCaseOne_text">
                          <div>案例</div>
                          <div>{{String("case").toUpperCase()}}</div>
                          <div><b>—</b></div>
                        </div>
                        <div class="caseContent">
                          <div v-for="(item,index) in caseData" :key="index" class="caseContentHover" :style="{'height': fitHeight + 'px',}" @click="goDetailsPage(item)"> 
                            <div class="caseContentImg">
                              <img :src=" $SrcUrl + item.picture" alt="" style="width:100%;height:100%;z-index: -1;">
                            </div>
                            <div class="positionAbsolute">
                              <div class="caseContentText">
                                {{ item.name }}
                              </div>
                              <div class="caseContentHoverSee" >
                                <!-- {{ item.name }} -->
                                <img src="../static/seeIcon.png" alt="" style="width:100%;height: 100%;">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="exampleCaseTwo">
                          <el-button plain @click="goEngineeringCase()">更多案例 ></el-button>
                        </div>
                      </div>
                    </div>
                    <Footer>
                      <!-- <template #footerBanner>
                        <img :src=" $SrcUrl  + srcFooter" alt="">
                      </template> -->
                    </Footer>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script>
import Menu from "@/components/Menu"
import TopBanner from "@/components/TopBanner"
import Module from "@/components/Module"
import Footer from "@/components/Footer"
import AboutUs from "@/components/AboutUs"
import Honors from "@/components/Honors"
import CompanyStrength from "@/components/CompanyStrength"
import { pictureData } from "@/utils/advertiseImgData"
    
    export default {
        name: 'Home',
        components: {
            Menu,Module,Footer,AboutUs,Honors,CompanyStrength,TopBanner
        },
        data(){
            return{
                screenWeight: 0,        // 屏幕宽度
                screenHeight: 0,        // 屏幕高度
                index: 1,               // 用于判断翻页
                curIndex: 1,            // 当前页的index
                startTime: 0,           // 翻屏起始时间  
                endTime: 0,             // 上一次翻屏结束时间
                nowTop: 0,              // 翻屏后top的位置
                pageNum: 0,             // 一共有多少页
                main: Object,
                obj: Object,


                windowHeight:'',
                windowWidth:'',
                fitHeight:'',
                activeIndex: '1',
                activeIndex2: '1',
                titleCHNName:'公司介绍',
                activeName_companyKey:'21',
                activeName: '13',
                doorsType:[],
                companyKey:[],
                doorsData:[],
                src:[require('@/static/banner1.png')],
                srcTop:[
                // require('@/static/banner1.png'),
                require('@/static/banner2.png')
                ],
                bannershow:true,
                srcFooter:[require('@/static/fooderImg1.png')],
                doorsAdvData:[],
                autoplay: true, //自动播放
                caseData:[],
                businessBenefitData:[],
                productAreaData:[{
                id:0,
                logoTypeName:'诊疗区',
                englishName:'treatment area',
                bgColor:'#f2d5c6',
                child:[
                    {num:1,name:'诊疗室门'},
                    {num:2,name:'CT室门'},
                    {num:3,name:'卫生间门'}
                ]}],
            }
        },
        watch:{
            'activeName':{
                handler(newVal) {
                this.activeName = newVal
                this.getDoorsData();
                this.getProductAreaData();
                }
            },
            'activeName_companyKey':{
                handler(newVal){
                console.log('activeName_companyKey',newVal,typeof newVal);
                }
            },
        },
        mounted(){
          document.querySelector('.hospitalDoorArea').style.height =  document.documentElement.clientHeight + 'px';
          document.querySelector('.caseFoot').style.height =  document.documentElement.clientHeight + 'px'
          var foot = document.querySelector('.footerContain').clientHeight
          document.querySelector('.exampleCaseOne').style.height = document.documentElement.clientHeight - foot +'px'
          // console.log('----------------***************',);

          // 公司介绍内容高度
          const intro = document.querySelector('.moduleContent').clientHeight + 29
          document.querySelector('.aboutUs').style.height = document.documentElement.clientHeight - intro +'px'
          
          this.screenWeight = document.documentElement.clientWidth;
          this.screenHeight = document.documentElement.clientHeight;
          this.main = document.getElementById("main");
          this.obj = document.getElementsByTagName("div");
          for (let i = 0; i < this.obj.length; i++) {
              if (this.obj[i].className == 'page') {
                  // console.log('this.obj[i].style.height',this.screenHeight + "px");
                  this.obj[i].style.height = this.screenHeight + "px";
              }
          }
          this.pageNum = document.querySelectorAll(".page").length;

          // 浏览器兼容      
          if ((navigator.userAgent.toLowerCase().indexOf("firefox") != -1)) {
              document.addEventListener("DOMMouseScroll", this.scrollFun, false);
          } else if (document.addEventListener) {
              document.addEventListener("mousewheel", this.scrollFun, false);
          } else if (document.attachEvent) {
              document.attachEvent("onmousewheel", this.scrollFun);
          } else {
              document.onmousewheel = this.scrollFun;
          };
          
          this.windowHeight = window.innerHeight;
          this.windowWidth = window.innerWidth;
          
          // this.getData();
          this.getAdvertise();
          this.getCaseData();
          this.getDoorsData();
          this.getProductAreaData();
          
          this.$http.get('/system/productType/getProTypeS').then(res => {
              this.doorsType = res;
          })
          this.$http.get('/system/contentType/getKeyS').then(res=>{
              this.companyKey = res.chilren;
          })
        },
        methods:{
            scrollFun(event) {
                this.startTime = new Date().getTime();
                // mousewheel事件中的 “event.wheelDelta” 属性值：返回的如果是正值说明滚轮是向上滚动
                // DOMMouseScroll事件中的 “event.detail” 属性值：返回的如果是负值说明滚轮是向上滚动
                let delta = event.detail || (-event.wheelDelta);
                // 如果当前滚动开始时间和上次滚动结束时间的差值小于1.5s，则不执行翻页动作，这样做是为了实现类似节流的效果
                if ((this.startTime - this.endTime) > 500) {
                    if (delta > 0 && parseInt(this.main.offsetTop) >= -(this.screenHeight * (this.pageNum - 2))) {
                        // 向下滚动
                        this.index++;
                        this.toPage(this.index);
                    }else if (delta < 0 && parseInt(this.main.offsetTop) < 0) {
                        // 向上滚动
                        this.index--;
                        this.toPage(this.index);
                    }
                    // 本次翻页结束，记录结束时间，用于下次判断
                    this.endTime = new Date().getTime();
                }
            },
            // 翻页
            toPage(index) {
                if (index != this.curIndex) {
                    let delta = index - this.curIndex;
                    this.nowTop = this.nowTop - delta * this.screenHeight;
                    this.curIndex = index;
                }
            },
            handleClick(tab, event) {
                // console.log(tab, event);
            },
            handleSelect(key, keyPath) {
                // console.log(key, keyPath);
            },
            getAdvertise(){
                this.$http.get('/system/advertisement/getAdvS').then(res => {
                let { topImg, footerImg } = pictureData({picExplain:'商用门', data:res})
                this.srcTop = topImg;
                // this.mySwiper.destroy();           // 销毁旧实例
                this.$nextTick(function () {
                    // this.getData();
                });
                })
            },
            getCaseData(){
                this.$http.get('/system/cases/getCasesOneS').then(res => {
                this.caseData = res
                this.getCaseFitHeight()
                })
            },
            goEngineeringCase(){
                this.$router.push({
                path:"/EngineeringCase"
                })
            },
            goProductDetails(item){
                const id = item
                console.log('------------------item',item);
                this.$router.push({
                    name:'ProductDetails',
                    query:{
                        id
                    }
                })
            },
            goMoreProduct(cur){
                this.$router.push({
                name:'ProductCenter',
                query:{cur}
                })
            },
            goDetailsPage(item){
                this.$router.push({
                name:'DetailsPage',
                query:{lastPage:'EngineeringCase',id: item.id}
                })
            },
            getDoorsData(){
                this.$http.get('/system/product/getRecommendProS',{'productTypeid': this.activeName}).then(res => {
                this.doorsAdvData = res
                })
            },
            getProductAreaData(){
                this.$http.get('/system/logochild/getLogos/' + this.activeName).then(res => {
                this.productAreaData = res
                })
            },
            getCaseFitHeight(){ 
                if(this.caseData){
                setTimeout(()=>{
                    const elementResizeDetectorMaker = require("element-resize-detector");
                    let erd = elementResizeDetectorMaker();
                    erd.listenTo(document.querySelector(".caseContentHover"), (ele) => {
                        this.fitHeight = ele.offsetWidth*2/3
                    });
                },100)
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    html, body {
        height: 100%;
    }
 
    body, ul, li, a, p, div {
        /*慎删*/
        padding: 0px;
        margin: 0px;
    }
 
    #wrap {
        overflow: hidden;
        width: 100%;
    }
 
    #main {
        position: relative;
        transition:top 0.5s;
    }
 
    .page {
        /*谨删*/
        width: 100%;
        margin: 0;
        overflow-y: scroll;
    }
 
    #pageUl {
        position: fixed;
        right: 10px;
        bottom: 50%;
    }
 
    .active{
        color: red;
    }

 .content{
    width: 100%;
    // overflow: hidden;
  }
  .topBanner{
    width: 100%;
  }
  .imgFit{
    width: 100%;
  }
  .page ::v-deep {
    .businessBenefit{
      padding: 30px 0 !important;
      margin: 30px 0 !important;
    }
    .moduleContent{
        padding-top: 80px!important;
    }
    .businessBenefitContent1{
        padding: 2% 16px 0 !important;
    }
    .businessBenefitContent1:last-child{
      padding-bottom: 2%!important;
    }
    .busBeneWord{
        font-size: 14px;
    }
    .busBeneTitle[data-v-e4f16244] {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 18px;
      background: #0069AB;
      border: 1px solid #A7A7A7;
      border-radius: 10px;
      font-size: 28px;
      color: #FFFFFF;
      // text-align: center;
      // line-height: 48px;
    }
  }
  .tabModule{
    width: 100%;
  }
  .tabModule .tabHeader{
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: nowrap
  }
  .tabModule .tabHeader span{
    padding: 4px 10px;
    margin: 3px 36px;
  }
  .tabModule .tabHeader .active{
    color: #FFFFFF;
    background-color: #0069AB;
    border-radius: 4px;
  }
  .tabModule .tabContent{
    margin-bottom: 0!important;
  }
  .tabModule .tabContent .tabModuleItem{
    width: 100%;
  }
  .tabModule .tabContent .companyContent{
    display: flex;
    align-items: center;
    width: 88%;
    margin: 140px 6%;
  }
  .companyContent .contentWidth{
    width: 49%;
    margin: 0 1%;
  }
  .companyContent .contentWidth .introText{
    width: 78%;
    margin: auto 0;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:9; 
  }

  // 产品中心
  .hospitalDoor,.hospitalDoor2{
    width: 62%;
    display: inline-block;
  }
  .hospitalDoor .hospitalDoor_content1{
    margin: 6% 8%;
    position: relative;
    height: fit-content;
  }
  .hospitalDoorArea{
    .bg{    
      position: absolute;
      // width: 100%;
      // height: 100%;
      bottom: 0;
      z-index: -1;
    }
  }
  .hospitalDoor2 .hospitalDoor_content2{
    margin: auto 0;
    // width: 100% !important;
    // background-size: cover;
  }
  .hospitalDoor2 .hospitalDoor_content2 img{
    margin: 60px 0;
  }
   .hospitalDoor_content2 .hospitalSubarea{
    display: flex;
    width: 94%;
    font-size: 14px;
    margin: 0 3%;
  }
  .hospitalDoor_content2 .hospitalSubarea .hospitalSubarea_content{
    width: 25%;
  }
  .hospitalDoor_content2 .hospitalSubarea .hospitalSubarea_content .center{
    display: flex;
    justify-content: center;
  }
  .hospitalDoor_content2 .hospitalSubarea .hospitalSubarea_content div{
    margin: 6px 0;
    align-items: center;
  }
  .hospitalDoor_content2 .hospitalSubarea .hospitalSubarea_content .hosAreaTitle{
    font-size: 22px!important;
    font-weight: 500;
  }

  // 标签页
.tabStyle ::v-deep .el-tabs__nav-scroll{
    display: flex;
    justify-content: center;
}
  .tabStyle ::v-deep .el-tabs__nav-wrap::after{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 0px;
    background-color: rgba(0,0,0,0);
    z-index: -1;
}
.tabStyle ::v-deep .el-tabs__active-bar{
    height: 0;
}
.tabStyle ::v-deep .el-tabs__item.is-active {
    color: #FFFFFF;
    background: #0069AB;
    border-radius: 4px;
}
.tabStyle ::v-deep .el-tabs__item:hover{
    color: #0069AB;
}
.tabStyle ::v-deep .el-tabs__item.is-active:hover{
    color: #FFFFFF;
}
.tabStyle ::v-deep .el-tabs__item{
    padding: 4px 18px !important;
    height: unset;
    line-height: unset;
    margin: 0 30px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
}

  /* 案例  */
  .exampleCase{
    width: 100%;
  }
  .exampleCase .exampleCaseOne{
    width: 100%;
    height: fit-content;
    background: url('../static/exampleCase1.png');
    background-size:100% 100%;
  }
  .exampleCaseOne_text{
    padding-top: 80px;
  }
  .exampleCaseOne_text div{
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    font-size: 20px;
    display: flex;
    justify-content: center;
  }
  .exampleCase .exampleCaseOne .exampleCaseOne_text div:first-child{
    font-size: 42px;
  }
  
  .caseContent{
    width: 80%;
    padding: 3% 10%;
    display: inline-block;
    display: flex;
  }
  .caseContentHover{
    position: relative;
    width: 25%;
    margin: 10px;
    border-radius: 12px!important;
    border-bottom-left-radius: 12px!important;
    border-bottom-right-radius: 12px!important;
    overflow: hidden;
  }
  .caseContentImg{
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
  }
  .positionAbsolute{
    position: absolute;
    bottom: 0;
    width: 100%;
    // height: 20%;
    padding: 2% 0;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 16px;
    // font-size:calc(100vw * 18 / 1920);
    
    background: rgba($color: #000000, $alpha: 0.6);
    
    .caseContentText{
      display: block;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow:ellipsis;
    }
      .caseContentHoverSee{
        display: none;
      }
    }
    .caseContentHover:hover{
      .positionAbsolute{
        height: 100%;
        .caseContentText{
          display: none;
        }
        .caseContentHoverSee{
          display: block;
        }
      }
    }
  .exampleCaseTwo{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .exampleCaseTwo .el-button{
    width: 115px;
    height: 41px;
    background:rgba($color: #000000, $alpha: 0.3);
    color: #FFFFFF;
  }
</style>