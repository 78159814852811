<template>
    <div class="content">
        <!-- <Menu></Menu> -->
        <div style="">
            <img src="../static/serch.jpg" alt="" style="width:100%;height: 100%;">
        </div>
        <div style="background:grey;height:50px;width:62%;margin:3% 19%;display:flex;align-items:center;justify-content:center;text-align: center;">
          全站搜索结果：产品：{{productData.length}}个;新闻：{{newsData.length}}个
        </div>
        <div>
          <div style="width:100%;text-align:center;"><h1>产品</h1></div>
          <div class="caseContent">
            <div v-for="item in productData" :key="item.id" class="caseContentHover" 
                :style="{'height': fitHeight + 'px',}"  
                @click="goProductDetails(item)"> 
                <div class="caseContentImg"><img :src=" $SrcUrl + item.picture" alt="" style="width:100%;height:100%;z-index: -1;"></div>
                <div class="positionAbsolute">
                    <div class="caseContentHoverSee">
                      <img src="@/static/seeIcon.png" alt="" style="width:100%;height: 100%;">
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div style="clear:both;"></div>
        <div style="width:100%;text-align:center;"><h1>新闻</h1></div>
        
        <div class="newsAllContent">
          <div v-for="(item,index) in newsData" :key="item.id">
            <div @click="activeStyle(index)" :class="{ active: index == types }" class="newsContent">
              <div class="newsContents_left" :style="{'height':newsImgFitWidth + 'px','margin':'auto 0'}">
                <img :src="$SrcUrl  + item.picture" alt="" class="pic1">
                <div class="hoverSeeIcon">
                  <img src="../static/seeIcon.png" alt="">
                </div>
              </div>
              <div class="newsContents_right">
                <div class="newsTitle">{{item.name}}</div>
                <div class="newsText">{{item.textContent}}</div>
                <div><button type="" @click="goDetailsPage(item)">了解详情></button></div>
                <div class="newsTime">{{item.beginTime}}</div>
              </div>
            </div>
            <div style="height:2px;width:100%;background:#E3E3E3;"></div>
          </div>
        </div>
        <Footer>
          <template #footerBanner>
            <img v-if="srcFooter" :src=" srcFooter " alt="" style="width: 100%;">
          </template>
        </Footer>
    </div>
  </template>
  
  <script>
  import Menu from "@/components/Menu"
  import Footer from "@/components/Footer"
  import { pictureData } from "@/utils/advertiseImgData"
import { forEach } from 'element-resize-detector/src/collection-utils'

  export default {
    name: "SerchPage",
    components: {
      Menu,Footer
    },
    data () {
      return {
        srcTop:[],
        srcFooter:[require('@/static/fooderImg1.png')],
        serchData:[],
        flag:false,
        fitHeight:'',
        newsImgFitWidth:'',
        productData:[],
        newsData:[],
        types:0,
      }
    },
    methods: {
      
      goDetailsPage(item){
        this.$router.push({
          name:'DetailsPage',
          query:{
            lastPage:'newsCenter',id: item.id,
          }
        })
      },
      activeStyle(index){
        this.types = index
      },
      goProductDetails(item){
        const id = item.id
        this.$router.push({
            name:'ProductDetails',
            query:{
                id
            }
        })
      },
    },
    mounted(){
    },
    computed: {
      windowHeight:function() {
        return (window.innerHeight) + 'px';
      }
    },
    watch:{
      '$route': {
          handler: function (val, old){
            this.$http.get('/system/product/selectWuZhou/' + val.query.data ).then(res => {
              this.serchData = res
              console.log('this.serchData',this.serchData);
              console.log('this.serchData.wuZhou',this.serchData.wuZhou);
              // 产品部分
               this.productData = this.serchData.wuZhou.filter(data=>{
                return data.kinds == 1
              })
              if(this.productData.length > 0){
                setTimeout(()=>{
                  const elementResizeDetectorMaker = require("element-resize-detector");
                    let erd = elementResizeDetectorMaker();
                    erd.listenTo(document.querySelector(".caseContentHover"), (ele) => {
                      this.fitHeight = ele.offsetWidth*2/3
                    });
                },100) 
              }


              // 新闻部分
              this.newsData = this.serchData.wuZhou.filter(data=>{
                return data.kinds == 2
              })
              // this.total = res.total;
              console.log('this.newsData',this.newsData);
              this.newsData.forEach(item =>{
                item.textContent = '';
                const regex = new RegExp('(<\\/[a-z]+[0-9]*>|<[a-z]+[0-9]*([\\s\\S\\t][a-z]*="(([a-z]+|-|[0-9]*)*|:|(V)+|\\.)*")*>)|(<[a-z]+[0-9]*([\\s\\S\\t][a-z]*="([a-z]+|-|:|(\\s\\S)|(\\/)*|\\.|[0-9]*|_|[A-Z]*|\\(|,|\\)|;|[\u4e00-\u9fa5])*")*>)', 'g')
                let descript = regex[Symbol.replace](item.content, '')
                item.textContent += descript
              })
              if(this.newsData){
                setTimeout(()=>{
                    const elementResizeDetectorMaker = require("element-resize-detector");
                    let erd = elementResizeDetectorMaker();
                    erd.listenTo(document.querySelector(".newsContents_left"), (ele) => {
                    this.newsImgFitWidth = ele.offsetWidth*0.7
                  });
                },100)
              }
              if(this.serchData.wuZhou && this.serchData.wuZhou.length != 0){
                this.flag = true
              }
            })
        },
        // 立即处理 进入页面就触发
        immediate: true,
        // 深度监听 属性的变化
        deep: true
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .content{
    width: 100%;
  }

  .caseContent{
    width: 80%;
    margin: 0 18.5%;
}
  .caseContentHover{
    float: left;
    position: relative;
    width: 25%;
    margin: 10px;
    // border-radius: 12px!important;
    // border-bottom-left-radius: 12px!important;
    // border-bottom-right-radius: 12px!important;
    // overflow: hidden;
  }
  .caseContentImg{
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
  }
  .positionAbsolute{
    position: absolute;
    bottom: 0;
    width: 100%;
    // height: 20%;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    background: rgba($color: #000000, $alpha: 0.6);
        .caseContentHoverSee{
            display: none;
        }
    }
    .caseContentHover:hover{
        .positionAbsolute{
            height: 100%;
            .caseContentText{
                display: none;
            }
            .caseContentHoverSee{
                display: block;
            }
        }
    }




    // 新闻
    .newsAllContent{
  width: 62%;
  margin: 10px auto;
}
.active{
  background: #F5F5F5;
}

.newsContent{
  display: flex;
  margin: 30px 0;
  border-radius: 12px 0px 0px 12px;
}
.newsContent:hover{
  background: #F5F5F5;
}
.newsContent .newsContents_left{
  position: relative;
  width: 38%;
  border-radius: 12px;
  overflow: hidden;
} 
.newsContent .newsContents_left .pic1{
  width: 100%;
  height: 100%;
  z-index: -1;
}
.hoverSeeIcon{
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.5);
  border-radius: 12px;
  visibility: hidden;
}

.newsContent .newsContents_left:hover{
  .hoverSeeIcon{
    visibility: visible;
  }
}
.newsContents_right{
  flex: 1;
  font-family: Source Han Sans CN;
}
.newsContents_right div{
  margin: 3% 3% 0 8%;
  .leaveMsg form input, textarea, button{
    width: 108px;
    height: 36px;
    background: rgba($color: white, $alpha: 1.0);
    border: 1px solid #A7A7A7;
    border-radius: 6px;
  }
}
.newsTitle{
  font-size: 28px;
  font-weight: 400;
}
.newsText{
  margin-right: 12%!important;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;				//溢出内容隐藏
  text-overflow: ellipsis;		//文本溢出部分用省略号表示
  display: -webkit-box;			//特别显示模式
  -webkit-line-clamp: 2;			//行数
  line-clamp: 2;					
  -webkit-box-orient: vertical;	//盒子中内容竖直排列
}
.newsTime{
  display: flex;
  justify-content: right;
}
  </style>