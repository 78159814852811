<template>
    <div class="businessBenefit">
        <div class="businessBenefitContent">
            <div class="businessBenefitContent1" v-for="item in businessBenefitData" :key="item.id">
                <div class="busBeneTitle">{{item.keyWord}}</div>
                <div class="busBeneWord">{{item.text}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import Module from "@/components/Module"
  export default{
    name: "CompanyStrength",
    components: {
        Module
    },
    data() {
      return {
        businessBenefitData:[
          {id:0,title:'1',content:'1流国际生产设备（意大利萨瓦尼尼、日本AMADA、芬兰Finn-Powoer等全球最尖端的门业生产设备'},
          {id:1,title:'6',content:'六大核心技术（可调节扣线技术；表面覆膜技术；可调节门框技术；铝合金百叶窗技术；无螺丝连接圆玻璃视窗技术；远距离通信指纹）'},
          {id:2,title:'31',content:'31年行业沉淀（1989年，国内第一樘入户门自美心诞生）'},
          {id:3,title:'100',content:'100余项国际国内专利认证'},
          {id:4,title:'250',content:'250余亩门业生产基地（美心现已发展成为全国规模大、种类齐全的门类生产综合性企业'},
          {id:5,title:'亿',content:'上亿樘门类产品每年从这里送往全世界（产能还在不断增长中）'},
          {id:6,title:'60',content:'60余个国家地区布局（至今，美心业务已拓展至 美国、澳大利亚、英国、法国等国家地区）'},]
      }
    },
    mounted(){
      this.$http.get('/system/situation/getId/' + 22).then(res => {
        // res.forEach(element => {
        //   console.log('element',element);
        //   element.chilren.forEach(data => {
        //     if(data.id == 22){
              this.businessBenefitData = res.keyWords
        //     }
        //   })
        // });
      })
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
.businessBenefit{
    height: fit-content;
    width: 100%;
    margin:5% 0 !important;
    padding: 5% 0;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('@/static/businessImg1.png') no-repeat;
    background-size: cover;
    Object-fit : contain
  }
  .businessBenefitContent{
    height: fit-content;
    /* // background: #FFFFFF;
    // opacity: 0.8; */
    background-color: rgba(255, 255 ,255 ,0.8);
    border-radius: 10px;
  }
  .businessBenefitContent1{
    z-index: 99999999999;
    display: flex;
    padding: 16px 16px;
    height: fit-content;
  }
  .busBeneTitle{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 18px;
    background: #0069AB;
    border: 1px solid #A7A7A7;
    border-radius: 10px;
    font-size: 26px;
    color: #FFFFFF;
    text-align: center;
    // line-height: 68px;
  }
  .busBeneWord{
    width: 512px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #333333;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;				//溢出内容隐藏
    text-overflow: ellipsis;		
    -webkit-line-clamp: 2;			//行数
    line-clamp: 2;					
    -webkit-box-orient: vertical;	
  }
</style>