<template>
  <div class="content">
    <!-- <Menu></Menu> -->
    <!-- <div>
      <img src="../static/product-banner.png" alt="" style="width:100%;height: 801px;">
    </div> -->
    <TopBanner :srcTop = "srcTop"></TopBanner>
    <div class="detailsContent">
      <div>
        <div class="title textStyle">{{detailesData.name}}</div>
        <div class="detailsContentIcon textStyle"><i class="el-icon-caret-bottom"></i></div>
        <div style="text-align:right;margin-right: 20px;">发布日期：{{ detailesData.beginTime }}&#12288;&#12288;点击：{{ detailesData.views }}</div>
        <div class="detailesLine"></div>
        <div class="htmlContent">
          <div class="ql-editor" v-html="detailesData.content"></div>
        </div>
      </div>
    </div>
    <Footer>
      <!-- <template #footerBanner>
        <img :src="footerImg" alt="" style="width: 100%;">
      </template> -->
    </Footer>
  </div>
</template>
  
<script>
  import Menu from "@/components/Menu"
  import TopBanner from "@/components/TopBanner"
  import Footer from "@/components/Footer"
  import "quill/dist/quill.core.css";
  import { pictureData } from "@/utils/advertiseImgData"
  export default {
    name: "ContactUs",
    components: {
      Menu,Footer,TopBanner
    },
    data () {
      return {
        footerImg:[require('@/static/fooderImg1.png')], 
        detailesData:{
          title:'萨瓦尼',
          time:'2022-02-12',
          clickNum:152,        
        },
        serchData:{},
        srcTop:[],
      }
    },
    methods: {
      getLastQueryData(){
        this.serchData = this.$route.query
        if(this.serchData.lastPage == 'newsCenter'){
          this.getNewsData();
        }else if(this.serchData.lastPage == 'EngineeringCase'){
          this.getEngineeringCase();
        }
      },
      getNewsData(){
        // 内页新闻广告图
        this.$http.get('/system/advertisement/getAdvS').then(res => {
          let { topImg, footerImg } = pictureData({picExplain:'新闻详情', data:res})
          console.log('topImg',topImg);
          console.log('footerImg',footerImg);
          if(topImg.length > 0){
            this.srcTop = topImg[0].data;
          }
          // if(footerImg.length > 0){
          //   this.srcFooter = footerImg[0].data
          // }
        });
        // 新闻内页数据
        this.$http.get('/system/news/getId/'+ this.serchData.id).then(res => {
          this.detailesData = res.data;
          console.log('thi.----',this.detailesData);
          const regex = new RegExp('<p><img')
          let descript = regex[Symbol.replace](this.detailesData.content, '<p style="text-align: center"><img')
          console.log('descript',descript);
        })
      },
      getEngineeringCase(){
        // 内页案例广告图
        this.$http.get('/system/advertisement/getAdvS').then(res => {
          let { topImg, footerImg } = pictureData({picExplain:'案例详情', data:res})
          console.log('topImg',topImg);
          console.log('footerImg',footerImg);
          if(topImg.length > 0){
            this.srcTop = topImg[0].data;
          }
          // if(footerImg.length > 0){
          //   this.srcFooter = footerImg[0].data
          // }
        });
        // 案例内页数据
        this.$http.get('/system/cases/getId/'+ this.serchData.id).then(res => {
          this.detailesData = res;
        })
      },
    },
    mounted(){
      this.getLastQueryData();
		},
  }
</script>
  
<style lang="scss" scoped>
  .content{
    width: 100%;
  }
  .detailsContent{
    margin: 5% 5% 0;
    width: 90%;
    .textStyle{
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
  .detailsContent .title{
    font-size: 36px;
  }
  .detailsContent .detailsContentIcon{
    font-size:40px;
    color: #0069AB;
  }
  .detailsContent .detailesLine{
    width:100%;
    margin: 6px 0 6% 0;
    border-bottom: 1px solid #006FBB;
    border-bottom: 1px solid ;
    border-image: -webkit-linear-gradient(30deg, rgba(255,255,255,0.00) 0%,#56A7FB 20%, rgba(255,255,255,0.00) 99%) 2 2 2 2;
    border-image: -moz-linear-gradient(30deg, rgba(255,255,255,0.00) 0%,#56A7FB 20%, rgba(255,255,255,0.00) 99%) 2 2 2 2;
    border-image: linear-gradient(1deg, rgba(255,255,255,0.00) 0%,#006FBB 50%, rgba(255,255,255,0.00) 100%) 2 2 2 2;
  }
  .detailsContent .htmlContent{
    width: 62%;
    margin: 3% 19%;
    display: flex;
    justify-content: center;
  }
  .detailsContent .htmlContent ::v-deep img{
    max-width: 100%;
  }
  </style>