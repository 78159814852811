<template>
  <div class="content">
    <!-- <Menu></Menu> -->
    <TopBanner :srcTop = "srcTop"></TopBanner>
    <div class="detailsContent">
      <div>
        <div class="title">
          <div>{{detailesData.name}}</div>
          <!-- <div class="engTitle">{{String(detailesData.englishName).toUpperCase()}}</div> -->
        </div>
        <div class="straightLine"></div>
        <div class="htmlContent">
          <div class="ql-editor" v-html="detailesData.content"></div>
        </div>
      </div>
    </div>
    <Footer>
      <!-- <template #footerBanner>
        <img :src=" $SrcUrl + srcFooter" alt="">
      </template> -->
    </Footer>
  </div>
</template>
  
<script>
  import Menu from "@/components/Menu"
  import TopBanner from "@/components/TopBanner"
  import Footer from "@/components/Footer"
  import { pictureData } from "@/utils/advertiseImgData"
  export default {
    name: "ProductDetails",
    components: {
      Menu,Footer,TopBanner
    },
    data () {
      return {
        serchId: 0,
        detailesData:[],
        srcTop:[],
        srcFooter:[],
      }
    },
    methods: {
      getLastQueryData(){
        this.serchId = this.$route.query.id
      },
      getProductData(){
        this.$http.get('/system/product/getId/'+ this.serchId).then(res => {
          this.detailesData = res;
        })
      },
      getAdvertise(){
        this.$http.get('/system/advertisement/getAdvS').then(res => {
          let { topImg, footerImg } = pictureData({picExplain:'产品详情', data:res})
          if(topImg.length > 0){
            this.srcTop = topImg[0].data;
          }
          // if(footerImg.length > 0){
          //   this.srcFooter = footerImg[0].data
          // }
        })
      },
    },
    mounted(){
      console.log('--------------',);
      this.getLastQueryData();
      this.getProductData();
      this.getAdvertise();
    },
  }
</script>
  
<style lang="scss" scoped>
  .content{
    width: 100%;
  }
  .detailsContent{
    width: 100%;
  }
  .detailsContent .title{
    font-size: 36px;
    text-align: center;
    margin: 3%;
    .engTitle{
      font-size: 14px;
    }
  }
  .detailsContent .straightLine{
    width: 100%;
    height: 1px;
    background: #D8D8D8;
  }
  .detailsContent .htmlContent{
    width: 62%;
    margin: 3% 19%;
    display: flex;
    justify-content: center;
  }
  .detailsContent .htmlContent ::v-deep img{
    max-width: 100%;
  }
  </style>