<template>
  <div class="honors_Content">
      <!-- <div class="honorsContent" v-for="item in honorImg" :key="item.id">
        <el-image style="width: 100%;height: 100%;" :src="item.details"  fit="fill"></el-image>
      </div>  v-html="companyHonor"-->
      <div class="honorsContent">
        <div class="honorsImg" v-for=" (item,index) in companyHonor" :key="index">
          <el-image :src=" $SrcUrl  +  item" alt="" style="width:100%" fit="cover"></el-image>
        </div>
        <div v-if="btnShow" class="seeMoreBtn">
          <router-link to='/Hornor'>
            <el-button>查看更多></el-button>
          </router-link>
        </div>
      </div>
  </div>
  
</template>
<script>
  export default{
    name: "Honors",
    data() {
      return {
        btnShow:false,
        companyHonor:[],
        honorImg:[
          {details:require('@/static/honorImg1.png'),id:0},
          {details:require('@/static/honorImg1.png'),id:1},
          {details:require('@/static/honorImg1.png'),id:2},
          {details:require('@/static/honorImg1.png'),id:3},
          {details:require('@/static/honorImg1.png'),id:4},
          {details:require('@/static/honorImg1.png'),id:5},]
      }
    },
    mounted(){
      this.$http.get('/system/situation/getId/' + 26).then(res => {
        if(res.picture){
          if(this.$route.path == '/Main'){
            this.btnShow = true
            this.companyHonor = res.picture.split(',').slice(0,3)
          }else{
            this.companyHonor = res.picture.split(',')
          }
        }
      })
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
.honors_Content{
  background: url('../static/honorBgImg.png') no-repeat;
  width: 100%;
  display: flex;
  background-size: cover;
  background-position: center;
}
.honorsContent{
  width: 62%;
  margin: 3% 19%;
  display: flex;
  flex-wrap: wrap;
}
.honorsImg{
  width: 30%;
  margin: 1% 1.5%;
  /* float: left; */
}
.seeMoreBtn{
  width: 100%;
  text-align: center;
  margin: 10px 0;
}
.seeMoreBtn .el-button{
  width: 115px;
  height: 41px;
  background:rgba($color: #000000, $alpha: 0.3);
  color: #FFFFFF;
}
.seeMoreBtn .el-button:hover{
  color: rgba($color: #000000, $alpha: 0.7);
  border: 1px solid rgba($color: #FFFFFF, $alpha: 0.3);
  background:rgba($color: #FFFFFF, $alpha: 0.3);
}
</style>