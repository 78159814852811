<template>
  <div style="width:100%;">
      <div class="topBanner">
        <template v-if="!insideBanner">
          <!-- <div class="swiper-container swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in srcTop" :key="index">
                  <img v-if="item.type == '图片'" class="imgFit" :style="{'height': bannerFitHeight + 'px',}" :src=" $SrcUrl + item.data" alt="">
                  <video autoplay controls id="video1" v-if="item.type == '视频'">
                    <source :src=" $SrcUrl + item.data">
                  </video>
                  <video v-if="item.type == '视频'" class="imgFit" :style="{'height': bannerFitHeight + 'px',}" :src=" $SrcUrl + item.data" autoplay controls alt=""></video>
                </div>
            </div>
            <div class="swiper-pagination">1 2 3 4 5</div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div> -->
          <el-carousel :interval="5000" :height="bannerFitHeight + 'px'" :autoplay="autoplay">
            <el-carousel-item v-for="(item,index) in srcTop" :key="index">
              <div v-if=" item.type == '图片' &&  $route.path == '/Main'" class="indexImg" :style="{'background-image':`url(${$SrcUrl + item.data})`}" ></div>
              <div v-if="item.type == '视频'" class="playBtn">
                <video id="video1" :src=" $SrcUrl + item.data" class="imgFit " autoplay controls muted  ref="vueMiniPlayer"></video>
                <i class="el-icon-video-play" v-show="isPaly" @click="playVid()"></i>
                <i class="el-icon-video-pause" v-show="!isPaly" @click="pauseVid()"></i>
              </div>
            </el-carousel-item>
          </el-carousel>
        </template>
        <template v-if="insideBanner">
          <img :src=" $SrcUrl  + srcTop" :style="{'height': inside_bannerFitHeight + 'px','width':'100%'}" alt="" >
        </template>
      </div>
  </div>
</template>
<script>
// import Swiper from 'swiper'
  export default{
    props:{
      insideBanner: {
        type: Boolean,
        default: true
      },
      srcTop:{}
    },
    name: "TopBanner",
    data() {
      return {
        bannerFitHeight:'',
        inside_bannerFitHeight:'',
        // mySwiper:null,
        autoplay:true,
        isPaly: true,
      }
    },
    mounted(){
      
      // this.mySwiper.destroy();           // 销毁旧实例
      this.$nextTick(function () {
        this.getData();
      });
      setTimeout(()=>{
        const elementResizeDetectorMaker = require("element-resize-detector");
        let erd = elementResizeDetectorMaker();
        erd.listenTo(document.querySelector(".topBanner"), (ele) => {
          this.bannerFitHeight = document.documentElement.offsetHeight;
          this.inside_bannerFitHeight = ele.offsetWidth*0.252
        });

        var elevideo = document.getElementById("video1");
        if(this.$route.path == '/Main' && elevideo){
          elevideo.pause();
        }
      },100)
    },
    methods:{
      playVid(){
        var myVideo = document.getElementById("video1");
        myVideo.play();
        this.autoplay = false;
        this.isPaly = !this.isPaly 
      },
      pauseVid(){
        var myVideo = document.getElementById("video1");
        myVideo.pause();
        this.autoplay = true;
        this.isPaly = !this.isPaly 
      },
      getData() {
        // this.mySwiper = new Swiper(".swiper-container", {

        //   loop: true,
        //   pagination:{
        //     el:'.swiper-pagination',
        //     clickable:true
        //   },
        //   navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev',
        //   },
        // });
        // console.log(this.mySwiper);
      },
    },
  }
</script>

<style scoped>
.topBanner .el-image{
    display: block;
  }
.indexImg{
  height: 100%;
  background-size: cover;
  background-position: center;
}
.imgFit{
  width:100%;
  height: 100%;
}
.playBtn{
  position: relative;
  z-index: 9;   
  height: 100%;
  width: 100%;
}
.el-icon-video-play,.el-icon-video-pause{
  font-size: 50px;
  width: 100%;
  height: 90%;
  top: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: white;
  position: absolute;
  opacity:0;
  animation:fadenum 1s;
}
@keyframes fadenum{
          0%{opacity:1;}
          99%{opacity:1;}
          100%{opacity:0;}
}
</style>