<template>
  <div id="app">
    <Menu></Menu>
    <router-view/>
  </div>
</template>

<script>
import Menu from "@/components/Menu"
  export default {
    name: 'App',
    components: {
      Menu
    },
    data () {
      return {
      }
  
    },
    watch: {
      $route: function (to, from) {
        document.getElementById('app').scrollTop = 0;
        window.scrollTo(0, 0) 
        document.body.scrollTop = 0 
        document.documentElement.scrollTop = 0 
      },
    }, 
    methods: {
      _isMobile() {
        let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        return flag;
      },
    },
    beforeRouteLeave(to, from , next){ 
      next(false)
    },
    created(){ 
      if (this._isMobile()) {
        var url = 'https://mobile.svndoor.com'
        window.open(url,"_self");
      }
    },
    mounted() {
    },
  }
  </script>
  <style>
  body {
    cursor:default;
    font-size: 15px;
    margin: 0px;
    padding: 0px;
    font-family: Source Han Sans CN;
    overflow-x: hidden;
  }
  html,body,#app{
    height: 100%;
  }
  </style>
  