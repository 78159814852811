<template>
  <!-- Swiper -->
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div id="page1" class="page">
          <TopBanner :srcTop = "srcTop"  :insideBanner="false"></TopBanner>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="page">
          <Module id="companyIntro" ModuleTitle1="公司" ModuleTitle2="介绍" ModuleTitleEng="company introduction">
            <template #content>
              <el-tabs v-model="activeName_companyKey" @tab-click="handleClick" class="tabStyle">
                <el-tab-pane v-for="item in companyKey" :key="item.id" :label="item.name" :name="String(item.id)"></el-tab-pane>
              </el-tabs>
            </template>
          </Module>
          <el-row>
            <el-col>
              <div v-show=" activeName_companyKey == '0'" class="tabModuleItem">
                <AboutUs></AboutUs>
              </div>
              <div v-show=" activeName_companyKey == '1'" class="tabModuleItem">
                <CompanyStrength></CompanyStrength>
              </div>
              <div v-show=" activeName_companyKey == '2'" class="tabModuleItem">
                <Honors></Honors>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="swiper-slide">
        <div style="background: #f2f2f2;" id="page3" class="page">
          <Module ModuleTitle1="产品" ModuleTitle2="中心" ModuleTitleEng="Product center">
            <template #content>
              <el-tabs v-model="activeName" @tab-click="handleClick" class="tabStyle">
                <el-tab-pane v-for="item in doorsType" :key="item.id" :label="item.name" :name="String(item.id)"></el-tab-pane>
              </el-tabs>
            </template>
          </Module>
          <div class="hospitalDoorTab" style="display:flex;justify-content:center;width: 100%;position: relative;">
            <div class="hospitalDoor">    
              <div class="hospitalDoor_content1">
                <template v-if="doorsAdvData.length != 0">
                  <div style="font-size:36px;writing-mode: tb-rl;position: absolute;left: 15%;">{{doorsAdvData[0].titleC}}</div>
                  <div style="color: #005189;font-size: 32px;writing-mode: tb-rl;position: absolute;left: 20%;top: 8%;z-index: 9999;">{{doorsAdvData[0].titleE}}</div>
                  <div style="width:60%" @click="goProductDetails(doorsAdvData[0].id)">
                  <img :src=" $SrcUrl  +  doorsAdvData[0].picture" style="border: 14px solid #A7A7A7;fit:contain;width: 100%;margin-top: 18%;" alt="">
                  </div>
                  <div style="position: absolute;right: 1%;top:0;width: 45%;">
                  <div v-if="doorsAdvData[1]"  @click="goProductDetails(doorsAdvData[1].id)">
                      <img :src="$SrcUrl  +  doorsAdvData[1].picture" style="border: 6px solid #377CA7;width: 100%;fit:contain;" alt="">
                  </div>
                  <div style="text-align: center;margin-top: 24%;font-size: 20px;"><div @click="goMoreProduct(activeName)">查看更多></div><div>-</div></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <div id="page4" class="page">
          <div class="hospitalDoorArea" style="display:flex;justify-content:center;width: 100%;position: relative;">
            <div class="hospitalDoor2"  style="margin:auto 0;">
              <div class="hospitalDoor_content2">
                <img :src=" $SrcUrl + productAreaData.logo" style="width: 100%;" alt="">
                <div class="hospitalSubarea">
                  <div class="hospitalSubarea_content" v-for="item in productAreaData.logoWords" :key="item.id">
                  <div class="hosAreaTitle center">{{item.logoTypeName}}</div>
                  <div class="center">{{String(item.englishName).toUpperCase()}}</div>
                  <div  :style="{'background': item.logoColor,width:'100%',height:'8px'}"></div>
                  <div class="center">
                    <div>
                      <div v-for="(item1,index) in item.child" :key="index">
                          <div style="display:flex;" @click="goProductDetails(item1.productId)">
                          <div style="width:20px;height:20px;background:#8e2a23;border-radius:50%;text-align:center;color:white;margin-right: 6px;">{{item1.sort}}</div>{{ item1.name }}
                          </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg">
            <img src="../static/honorBgImg_01.png" alt="" style="width: 100%;">
          </div>
        </div>
      </div>
      
      <div class="swiper-slide">
        <div id="page5" class="page">
          <div class="exampleCase">
            <div class="exampleCaseOne">
              <div class="exampleCaseOne_text">
                <div>案例</div>
                <div>{{String("case").toUpperCase()}}</div>
                <div><b>—</b></div>
              </div>
              <div class="caseContent">
                <div v-for="(item,index) in caseData" :key="index" class="caseContentHover" :style="{'height': fitHeight + 'px',}" @click="goDetailsPage(item)"> 
                  <div class="caseContentImg">
                    <img v-if="item.picture" :src=" $SrcUrl + item.picture" alt="" style="width:100%;height:100%;z-index: -1;">
                  </div>
                  <div class="positionAbsolute">
                    <div class="caseContentText">
                      {{ item.name }}
                    </div>
                    <div class="caseContentHoverSee" >
                      <!-- {{ item.name }} -->
                      <img src="../static/seeIcon.png" alt="" style="width:100%;height: 100%;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="exampleCaseTwo">
                <el-button plain @click="goEngineeringCase()">更多案例 ></el-button>
              </div>
            </div>
          </div>
          <Footer>
            <!-- <template #footerBanner>
              <img :src=" $SrcUrl  + srcFooter" alt="">
            </template> -->
          </Footer>
        </div>
      </div>
    </div>
    <!-- <div class="swiper-pagination"></div> -->
  </div>
</template>

<script>
import Menu from "@/components/Menu"
import TopBanner from "@/components/TopBanner"
import Module from "@/components/Module"
import Footer from "@/components/Footer"
import AboutUs from "@/components/AboutUs"
import Honors from "@/components/Honors"
import CompanyStrength from "@/components/CompanyStrength"
import { pictureData } from "@/utils/advertiseImgData"
import Swiper from 'swiper'

export default {
  name: 'test',
  components: {
    Menu,Module,Footer,AboutUs,Honors,CompanyStrength,TopBanner
  },
  data(){
    return{
      screenWeight: document.documentElement.clientWidth,    // 屏幕宽度
      screenHeight: document.documentElement.clientHeight,    // 屏幕高度
      fitHeight:'',
      activeIndex: '1',
      activeIndex2: '1',
      titleCHNName:'公司介绍',
      activeName_companyKey:'0',
      activeName: '13',
      doorsType:[],
      companyKey:[
        {id:0,name:'企业介绍'},
        {id:1,name:'企业优势'},
        {id:2,name:'资质荣誉'}
      ],
      doorsData:[],
      src:[require('@/static/banner1.png')],
      srcTop:[
      // require('@/static/banner1.png'),
      require('@/static/banner2.png')
      ],
      bannershow:true,
      srcFooter:[require('@/static/fooderImg1.png')],
      doorsAdvData:[],
      autoplay: true, //自动播放
      caseData:[],
      businessBenefitData:[],
      productAreaData:[{
      id:0,
      logoTypeName:'诊疗区',
      englishName:'treatment area',
      bgColor:'#f2d5c6',
      child:[
        {num:1,name:'诊疗室门'},
        {num:2,name:'CT室门'},
        {num:3,name:'卫生间门'}
      ]}],
    }
  },
  watch:{
    'activeName':{
      handler(newVal) {
      this.activeName = newVal
      this.getDoorsData();
      this.getProductAreaData();
      }
    },
    'activeName_companyKey':{
      handler(newVal){
      console.log('activeName_companyKey',newVal,typeof newVal);
      }
    },
    screenHeight (val) {
      let that = this;
      console.log("实时屏幕高度：",val);
      this.screenHeight = val
      location.replace(location)
    },
    screenWeight (val) {
      let that = this;
      console.log("实时屏幕宽度：",val,)
      this.screenWeight = val
    }
  },
  mounted(){
    document.querySelector('.hospitalDoorArea').style.height =  document.documentElement.clientHeight + 'px';

    var foot = document.querySelector('.footerContain').clientHeight
    document.querySelector('.exampleCase').style.height = document.documentElement.clientHeight - foot +'px'
    

    // 公司介绍内容高度
    const intro = document.querySelector('.moduleContent').clientHeight + 29

    document.querySelector('.honors_Content').style.height = this.screenHeight - intro + 'px';
    document.querySelector('.hospitalDoorTab').style.height = this.screenHeight - intro + 'px';

    document.querySelector('.aboutUs').style.height = document.documentElement.clientHeight - intro +'px'



    // // this.getData();
    this.getAdvertise();
    this.getCaseData();
    this.getDoorsData();
    this.getProductAreaData();
    
    this.$http.get('/system/productType/getProTypeS').then(res => {
      this.doorsType = res;
    })
    this.$http.get('/system/contentType/getKeyS').then(res=>{
      // this.companyKey = res.chilren;
    })
    
    // 页面一滑一屏
    new Swiper('.swiper-container', {
      direction: 'vertical',
      slidesPerView: 'auto',
      spaceBetween: 10,
      mousewheel: true,
      mousewheelForeceToAxis:true,
      mousewheelSensitivity: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  },
  methods:{handleClick(tab, event) {
      // console.log(tab, event);
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    getAdvertise(){
      this.$http.get('/system/advertisement/getAdvS').then(res => {
        let { topImg, footerImg } = pictureData({picExplain:'商用门', data:res})
        this.srcTop = topImg;
      })
    },
    getCaseData(){
      this.$http.get('/system/cases/getCasesOneS').then(res => {
        if(res.code !== 500){
          this.caseData = res
          this.getCaseFitHeight()
        }
      })
    },
    goEngineeringCase(){
      this.$router.push({
        path:"/EngineeringCase"
      })
    },
    goProductDetails(item){
      const id = item
      console.log('------------------item',item);
      this.$router.push({
        name:'ProductDetails',
        query:{
          id
        }
      })
    },
    goMoreProduct(cur){
      this.$router.push({
        name:'ProductCenter',
        query:{cur}
      })
    },
    goDetailsPage(item){
      this.$router.push({
        name:'DetailsPage',
        query:{lastPage:'EngineeringCase',id: item.id}
      })
    },
    getDoorsData(){
      this.$http.get('/system/product/getRecommendProS',{'productTypeid': this.activeName}).then(res => {
        this.doorsAdvData = res
        console.log('this.doorsAdvData',this.doorsAdvData);
      })
    },
    getProductAreaData(){
      this.$http.get('/system/logochild/getLogos/' + this.activeName).then(res => {
        this.productAreaData = res
      })
    },
    getCaseFitHeight(){ 
      if(this.caseData){
        setTimeout(()=>{
          const elementResizeDetectorMaker = require("element-resize-detector");
          let erd = elementResizeDetectorMaker();
          erd.listenTo(document.querySelector(".caseContentHover"), (ele) => {
            this.fitHeight = ele.offsetWidth*2/3
          });
        },100)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 100%;
  margin: unset;
  // margin-left: auto;
  // margin-right: auto;
}
.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.page{
  width: 100%;
  height: 100%;
}
.content{
  width: 100%;
// overflow: hidden;
}
.topBanner{
  width: 100%;
}
.imgFit{
  width: 100%;
}
.page ::v-deep {
  position: relative;
.businessBenefit{
  padding: 30px 0 !important;
  margin: 30px 0 !important;
}
.moduleContent{
    padding-top: 80px!important;
}
.businessBenefitContent1{
    padding: 2% 16px 0 !important;
}
.businessBenefitContent1:last-child{
  padding-bottom: 2%!important;
}
.busBeneWord{
    font-size: 14px;
}
.busBeneTitle[data-v-e4f16244] {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 18px;
  background: #005189;
  border: 1px solid #A7A7A7;
  border-radius: 10px;
  font-size: 28px;
  color: #FFFFFF;
  // text-align: center;
  // line-height: 48px;
}
.honors_Content{
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover!important;
  background-position: center;
}
}
.tabModule{
  width: 100%;
}
.tabModule .tabHeader{
  width: 100%;
  display: flex;
  justify-content: center;
  white-space: nowrap
}
.tabModule .tabHeader span{
  padding: 4px 10px;
  margin: 3px 36px;
}
.tabModule .tabHeader .active{
  color: #FFFFFF;
  background-color: #005189;
  border-radius: 4px;
}
.tabModule .tabContent{
  margin-bottom: 0!important;
}
.tabModule .tabContent .tabModuleItem{
  width: 100%;
}
.tabModule .tabContent .companyContent{
  display: flex;
  align-items: center;
  width: 88%;
  margin: 140px 6%;
}
.companyContent .contentWidth{
  width: 49%;
  margin: 0 1%;
}
.companyContent .contentWidth .introText{
  width: 78%;
  margin: auto 0;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #666666;
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box; 
  -webkit-box-orient:vertical;
  -webkit-line-clamp:9; 
}

// 产品中心
.hospitalDoor,.hospitalDoor2{
  width: 62%;
}
.hospitalDoor{
  display: flex;
  align-items: center;
  justify-content: center;
}
.hospitalDoor2{
  display: inline-block;
}
.hospitalDoor .hospitalDoor_content1{
  margin: 6% 8%;
  position: relative;
  height: fit-content;
}
.bg{    
  position: absolute;
  bottom: 0;
  z-index: -1;
  width:100%;
}

.hospitalDoor2 .hospitalDoor_content2{
  margin: auto 0;
}
.hospitalDoor2 .hospitalDoor_content2 img{
  margin-top: 60px;
}
.hospitalDoor_content2 .hospitalSubarea{
  display: flex;
  justify-content: center;
  width: 94%;
  font-size: 14px;
  margin: 0 3%;
}
.hospitalDoor_content2 .hospitalSubarea .hospitalSubarea_content{
  width: 25%;
}
.hospitalDoor_content2 .hospitalSubarea .hospitalSubarea_content .center{
  display: flex;
  justify-content: center;
}
.hospitalDoor_content2 .hospitalSubarea .hospitalSubarea_content div{
  margin: 6px 0;
  align-items: center;
}
.hospitalDoor_content2 .hospitalSubarea .hospitalSubarea_content .hosAreaTitle{
  font-size: 22px!important;
  font-weight: 500;
}

// 标签页
.tabStyle ::v-deep .el-tabs__nav-scroll{
  display: flex;
  justify-content: center;
}
.tabStyle ::v-deep .el-tabs__nav-wrap::after{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 0px;
  background-color: rgba(0,0,0,0);
  z-index: -1;
}
.tabStyle ::v-deep .el-tabs__active-bar{
  height: 0;
}
.tabStyle ::v-deep .el-tabs__item.is-active {
  color: #FFFFFF;
  background: #005189;
  border-radius: 4px;
}
.tabStyle ::v-deep .el-tabs__item:hover{
  color: #005189;
}
.tabStyle ::v-deep .el-tabs__item.is-active:hover{
  color: #FFFFFF;
}
.tabStyle ::v-deep .el-tabs__item{
  padding: 4px 18px !important;
  height: unset;
  line-height: unset;
  margin: 0 30px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
}

/* 案例  */
.exampleCase{
  width: 100%;
}
.exampleCase .exampleCaseOne{
  width: 100%;
  height: 100%!important;
  height: fit-content;
  background: url('../static/exampleCase1.png');
  background-size:100% 100%;
}
.exampleCaseOne_text{
  padding-top: 80px;
}
.exampleCaseOne_text div{
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  font-size: 20px;
  display: flex;
  justify-content: center;
}
.exampleCase .exampleCaseOne .exampleCaseOne_text div:first-child{
  font-size: 36px;
}

.caseContent{
  width: 80%;
  padding: 1% 10%;
  display: inline-block;
  display: flex;
}
.caseContentHover{
  position: relative;
  width: 25%;
  margin: 10px;
  border-radius: 12px!important;
  border-bottom-left-radius: 12px!important;
  border-bottom-right-radius: 12px!important;
  overflow: hidden;
}
.caseContentImg{
  width: 100%;
  height: 100%;
  margin-bottom: -4px;
}
.positionAbsolute{
  position: absolute;
  bottom: 0;
  width: 100%;
  // height: 20%;
  padding: 2% 0;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  // font-size:calc(100vw * 18 / 1920);

  background: rgba($color: #000000, $alpha: 0.6);

.caseContentText{
  display: block;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
  .caseContentHoverSee{
    display: none;
  }
}
.caseContentHover:hover{
  .positionAbsolute{
    height: 100%;
    .caseContentText{
      display: none;
    }
    .caseContentHoverSee{
      display: block;
    }
  }
}
.exampleCaseTwo{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1% 0;
}
.exampleCaseTwo .el-button{
  width: 115px;
  height: 41px;
  background:rgba($color: #000000, $alpha: 0.3);
  color: #FFFFFF;
}
</style>