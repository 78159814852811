export const pictureData = (dataAll) => {
    let resultData = {}
    let topData = []
    let footerData = []
    dataAll.data.forEach(element => {
        if(element.picExplain == dataAll.picExplain && element.position == '1'){
            if(element.picture){
                topData.push({'data':element.picture,'type':'图片'})
            }
            if(element.vedio){
                topData.push({'data':element.vedio,'type':'视频'})
            }
        }else if(element.picExplain == dataAll.picExplain && element.position == '0'){
            if(element.picture){
                footerData.push({'data':element.picture,'type':'图片'})
            }
            if(element.vedio){
                footerData.push({'data':element.vedio,'type':'视频'})
            }
        }
    });
    resultData['topImg'] = topData
    // resultData['footerImg'] = footerData
    console.log('topData',topData);
    // console.log('footerData',footerData);
    return resultData
}

// export const footerPic = (data) => {
//     let resultData = {}
//     let dataX = []
//     let dataValue = []
//     if (data.time == '年') {
//         data.data.forEach((item, index) => {
//             dataX.push(item.dataTime)
//             dataValue.push(item.yearCount)
//         })
//     } else if (data.time == '月') {
//         data.data.forEach((item, index) => {
//             dataX.push(item.dataTime)
//             dataValue.push(item.montCount)
//         })
//     }
//     resultData['dataX'] = dataX
//     resultData['dataValue'] = dataValue
//     return resultData
// }
