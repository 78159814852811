import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from './utils/http';
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style.css'

import "quill/dist/quill.core.css" //内页样式

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'


// 第一个是videoJs的样式，后一个是vue-video-player的样式，因为考虑到我其他业务组件可能也会用到视频播放，所以就放在了main.js内
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import Video from 'video.js'
import 'video.js/dist/video-js.css' 
Vue.prototype.$video = Video



//在main.js内
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer);



Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$SrcUrl ='https://api.svndoor.com', 

Vue.use(ElementUI)

new Vue({
  router,
  store,
  http,
  render: h => h(App)
}).$mount('#app')
