<template>
    <div class="content">
        <!-- <Menu @menuChange="menuChange"></Menu> -->
        <TopBanner :srcTop = "srcTop"></TopBanner>
        <div id="caseItem"></div>
        <div>
            <Module ModuleTitle1="工程" ModuleTitle2="案例" ModuleTitleEng="company introduction">
                <template #content>
                    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabStyle">
                        <el-tab-pane v-for="item in caseType" :key="item.id" :label="item.name" :name="String(item.id)"></el-tab-pane>
                    </el-tabs>
                </template>
            </Module>
            <el-row>
                <el-col>
                    
                    <div class="caseContent">
                        <div v-for="item in caseData" :key="item.id" class="caseContentHover"  ref="text" :style="{'height': fitHeight + 'px',}"  @click="goDetailsPage(item)"> 
                            <div class="caseContentImg"><img :src=" $SrcUrl + item.picture" alt="" style="width:100%;height:100%;z-index: -1;"></div>
                            <div class="positionAbsolute">
                                <div class="caseContentText">
                                    {{ item.name }}
                                </div>
                                <div class="caseContentHoverSee">
                                    <img src="../static/seeIcon.png" alt="" style="width:100%;height: 100%;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                    <div class="EngineeringCasePagination">
                        <el-pagination  
                            prev-text="上一页" next-text="下一页"
                            background
                            layout="prev, pager, next"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"
                            :page-size="currentSize"
                            :total="total">
                        </el-pagination>
                    </div>
                </el-col>
            </el-row>
        </div>
        <Footer>
            <template #footerBanner>
                <img :src=" $SrcUrl  + srcFooter" alt="">
            </template>
        </Footer>
    </div>
  </template>
  
  <script>
  import Menu from "@/components/Menu"
  import TopBanner from "@/components/TopBanner"
  import Module from "@/components/Module"
  import Footer from "@/components/Footer"
  import { pictureData } from "@/utils/advertiseImgData"
  export default {
    name: "EngineeringCase",
    components: {
        Menu,Module,Footer,TopBanner
    },
    data () {
      return {
        windowHeight:'',
        currentPage:1,
        currentSize:6,
        total:0,
        fitHeight:'',
        caseData:[],
        srcTop:[],
        srcFooter:[],
        activeName: '1',
        activeId: 1,
        caseType:[],
      }
    },
    watch:{
        'activeName':{
            handler(newVal) {
                console.log('activeNamenewVal',newVal,typeof newVal);
                this.activeName = newVal
                this.getCaseData();
            }
        },

    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        handleCurrentChange(val){
            console.log(`当前页: ${val}`);
            this.currentPage = val
            this.getCaseData();
        },
        menuChange(data) {
            this.activeName = data.toString()
            this.getCaseData();
        },
        goDetailsPage(item){
            this.$router.push({
                name:'DetailsPage',
                query:{lastPage:'EngineeringCase',id: item.id}
            })
        },
        getCaseData(){
            this.$http.get('/system/cases/getCasesS',{'pageNum':this.currentPage, 'pageSize': this.currentSize,'systemTypeId': this.activeName}).then(res => {
                if(res.code == 200){
                    this.caseData = res.rows
                    this.total = res.total
                    this.getCaseFitHeight()
                }
            })
        },
        getCaseType(){
            this.$http.get('/system/systemType/getCaseTypeS').then(res => {
                this.caseType = res
            })
        },
        getAdvertise(){
            this.$http.get('/system/advertisement/getAdvS').then(res => {
            let { topImg, footerImg } = pictureData({picExplain:'工程案例', data:res})
                if(topImg.length > 0){
                    this.srcTop = topImg[0].data;
                }
                // if(footerImg.length > 0){
                //     this.srcFooter = footerImg[0].data
                // }
            })
        },
        getCaseFitHeight(){ 
            if(this.caseData){
                setTimeout(()=>{
                    const elementResizeDetectorMaker = require("element-resize-detector");
                    let erd = elementResizeDetectorMaker();
                    erd.listenTo(document.querySelector(".caseContentHover"), (ele) => {
                        this.fitHeight = ele.offsetWidth*2/3
                    });
                },100)
            }
        },
    },
    mounted(){
        this.getAdvertise();
        this.windowHeight = window.innerHeight;
        this.getCaseType();
        this.getCaseData();
        if(sessionStorage.getItem('TypeId')){
            this.activeName = sessionStorage.getItem('TypeId')
            this.getCaseData();
        }
    },
    beforeDestroy() {
    //   sessionStorage.removeItem('TypeId')
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .content{
    width: 100%;
    height: 100%;
  }
  .tabModule .tabHeader .active{
    background-color: #005189;
  }
  .caseContent{
    width: 80%;
    margin: 0 18.5%;
}
  .caseContentHover{
    float: left;
    position: relative;
    width: 25%;
    margin: 10px;
    border-radius: 12px!important;
    border-bottom-left-radius: 12px!important;
    border-bottom-right-radius: 12px!important;
    overflow: hidden;
  }
  .caseContentImg{
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
  }
  .positionAbsolute{
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background: rgba($color: #000000, $alpha: 0.6);
        .caseContentHoverSee{
            display: none;
        }
        .caseContentText{
            padding: 2% 0;
            display: block;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
        }
    }
    .caseContentHover:hover{
        .positionAbsolute{
            height: 100%;
            .caseContentText{
                display: none;
            }
            .caseContentHoverSee{
                display: block;
            }
        }
    }
//   .caseContent{
//     width:80%;
//     margin: 0 10%;
//     overflow: hidden;
//     .caseContentHover{
//         float: left;
//         width: 28%;
//         margin: 2.65%;
//         position: relative;
//         border-radius: 12px;
//         overflow: hidden;
//         .caseContentText,.caseContentHoverSee{
//             width: 100%;
//             position: absolute;
//             bottom: 4px;
//             z-index: 9999;
//         }
//         .caseContentText{
//             background: rgba(0,0,0,0.5);
//             text-align: center;
//             padding: 4% 0;
//             color: #FFFFFF;
//         }
//         .caseContentHoverSee{
//             display: none;
//             background: rgba(0,0,0,0.5);
//         }
//     }
//     .caseContentHover:hover{
//         .caseContentText{
//             display: none;
//         }
//         .caseContentHoverSee{
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             text-align: center;
//             height: 99%;
//         }
//     }
//   }
//   .caseContent{
//     position: relative;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//   }
//   .caseContentHover{
//     width: 23%;
//     background-size: cover;
//     margin: 34px;
//     overflow: hidden;
//     border-radius: 12px;
//   }
//   .caseContentBlock1{
//     position: relative;
//   }
//   .caseContentBlock .caseContentText{
//     position: absolute;
//     bottom: 4px;
//     z-index: 9999;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 6% 0;
//     width: 100%;
//     font-size: 16px;
//     font-weight: 400;
//     color: #FFFFFF;
//     background: rgba(0,0,0,0.5);
//     border-radius: 0 0 12px 12px;
//   }
//   .caseContentHoverSee{
//     position: absolute;
//     bottom: 4px;
//     visibility: hidden;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     height: 100%;
//     background: rgba(0,0,0,0.5);
//     border-radius: 12px;
//   }
//   .caseContentHover:hover{
//     .caseContentBlock .caseContentText{
//         display: none;
//     }
//     .caseContentHoverSee{
//         visibility: visible;
//     }
//   }

.clear{
    clear: both;
}

// 标签页
.tabStyle ::v-deep .el-tabs__nav-wrap::after{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 0px;
    background-color: #005189;
    z-index: -1;
}
.tabStyle ::v-deep .el-tabs__nav-scroll{
    display: flex;
    justify-content: center;
}
.tabStyle ::v-deep .el-tabs__active-bar{
    height: 0;
}
.tabStyle ::v-deep .el-tabs__item.is-active {
    color: #FFFFFF;
    background: #005189;
    border-radius: 4px;
}
.tabStyle ::v-deep .el-tabs__item:hover{
    color: #000000;
}
.tabStyle ::v-deep .el-tabs__item.is-active:hover{
    color: #FFFFFF;
}
.tabStyle ::v-deep .el-tabs__item{
    padding: 4px 18px !important;
    height: unset;
    line-height: unset;
    margin: 0 50px;
}


  /* 分页样式 */
.EngineeringCasePagination{
    display: flex;
    justify-content: center;
    margin: 30px 0;
}
.EngineeringCasePagination ::v-deep .el-pagination.is-background .btn-next,
.EngineeringCasePagination ::v-deep  .el-pagination.is-background .btn-prev,
.EngineeringCasePagination ::v-deep .el-pagination.is-background .el-pager li {
    background: #FFFFFF;
    border: 1px solid #A7A7A7;
}

.EngineeringCasePagination ::v-deep .el-pagination.is-background .el-pager li:hover{
    color: #005189;
}
.EngineeringCasePagination ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #005189;
    color: #fff;
  }
.EngineeringCasePagination ::v-deep .el-pagination .btn-prev,.EngineeringCasePagination ::v-deep .el-pagination .btn-next {
    width: 64px;
}
  </style>